//=================================================================================================
//
//        Import Base Constants
//
//=================================================================================================
@import '../../node_modules/bootstrap/scss/functions'; 
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/variables';


//=================================================================================================
//
//        Custome Constants
//
//=================================================================================================
$enable-gradients: true;
$enable-shadows: true;

$nav-tabs-link-active-bg: rgb(85, 17, 43);



$link-color:                              rgb(85, 17, 43);
$link-decoration:                         underline;
$link-shade-percentage:                   20%;
$link-hover-color:                        shift-color($link-color, $link-shade-percentage);

$btn-link-hover-color:                    rgb(85, 17, 43);

$AlcoveSunTeal: rgb(20, 179, 139);
$AlcoveSunRed: rgb(85, 17, 43);
$AlcoveSunWhite: rgb(240, 236, 222);

$tempCustom:    rgb(172, 25, 230);





$carousel-control-color: rgb(144, 141, 132);


// $carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
// $carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");






//=================================================================================================
//
//        Import Base Bootstrap Classes/ All of Bootstrap imports
//
//=================================================================================================


//Imports all, not ideal, better to import all that will use
//Use for now to make sure changes work, then remove and import needed
//@import '../../node_modules/bootstrap/scss/bootstrap.scss';

 @import '../../node_modules/bootstrap/scss/_nav.scss';



//=================================================================================================
//
//        Custom/Extended/Overridden Classes
//
//=================================================================================================


.nav-tabs {
    .nav-link.active{
        //@extend .nav-link, .active;
        //color: $nav-tabs-link-active-color;
        color: #247;
        background-color: $nav-tabs-link-active-bg;
        border-color: $nav-tabs-link-active-border-color;
    }
}

// .card
// .btn .btn-primary {
    
//     font-weight: $font-weight-normal;
//     color: $btn-link-color;
//     text-decoration: $link-decoration;
  
//     &:hover {
//       color: $tempCustom;
//       text-decoration: $link-hover-decoration;
//       background-color:  $pink;
      
//     }
  
//     &:focus {
//       text-decoration: $link-hover-decoration;
//     }
  
//     &:disabled,
//     &.disabled {
//       color: $btn-link-disabled-color;
//     }
  
//     // No need for an active state here
//   }

  .appSelectOption{
    &:hover{
      color: $tempCustom;
    //background-color:  #247;
    // background-image: radial-gradient( rgba(255,0,0,0) 0%, transparent 70%, rgba(17, 50, 100, 0.411) 98%);
    // background-image: conic-gradient(from 315deg at 50% 50%, rgba(255,0,0,0) 12.5%,rgba(17, 50, 100, 0.411) 35%);
    text-decoration: $link-hover-decoration;
    box-shadow: 8px 8px 8px $gray-600;
    transform:scale(.98);
    border: $card-border-width solid $card-border-color;
    @include border-radius($card-inner-border-radius $card-inner-border-radius $card-inner-border-radius $card-inner-border-radius);
    .card-header{
      background-color: $card-bg;
    }
    .card-body{
      background-color: $card-bg;
    }
    }
    &:focus, &:active {
      text-decoration: $link-hover-decoration;
      border:$card-border-width solid $card-border-color;
    }
    
}




.carousel{
  background-color: $carousel-control-color;
  .carousel-control-prev-icon {
    background-image: escape-svg($carousel-control-prev-icon-bg);
  }
  .carousel-control-next-icon {
    background-image: escape-svg($carousel-control-next-icon-bg);
  }

  //   .carousel-control-prev-icon,
  //   .carousel-control-next-icon {
  //   // color: $pink;
  //   // filter: none;
  // }
}

.footer{
  background-color: $dark;
  .footerContent{
    color: $light;
    background-color: $link-color;
    border: 1px solid $link-color;
    border-radius: 5px;
  }
}

.appHighlight{
  
  transition: all 100ms ease-in-out;
}




.appHighlightActive{
  box-shadow: 0 0 20px 2px $AlcoveSunTeal;
  
}


 


// .nav-link-new{
//     //@extend .nav-link;
//     &:hover,
//     &:focus {
//     border-color: rgb(37, 26, 37);
//     // Prevents active .nav-link tab overlapping focus outline of previous/next .nav-link
//     isolation: isolate;
//     }
// }

// .nav-link-new.active{
//     //@extend .nav-link, .active;
//     //color: $nav-tabs-link-active-color;
//     color: #247;
//     background-color: #000;
//     border-color: $nav-tabs-link-active-border-color;
//   }

